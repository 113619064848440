import dispatchEvent from '../utils/dispatch-event';
import formatMoney from '../utils/currency';
import { addToCart } from '../utils/cart';
import { DrawerEvent } from "../enums/event";
import { handlePromotions } from "../utils/promotions";

const selectors = {
  atcButton: '[data-quick-atc]',
  cartDrawer: '[data-drawer="cart"]',
  selectedPrice: '[data-price]',
  selectedCompareAtPrice: '[data-compare-at-price]'
};

class QuickAdd extends HTMLElement {
  constructor() {
    super();

    this.atcButton = this.querySelector(selectors.atcButton);
    this.cartDrawer = document.querySelector(selectors.cartDrawer);
    this.selectedPrice = this.querySelector(selectors.selectedPrice);
    this.selectedCompareAtPrice = this.querySelector(
      selectors.selectedCompareAtPrice
    );

    this.registerEventListeners();
  }

  setPriceText(price) {
    this.selectedPrice.textContent = formatMoney(price);
  }

  setCompareAtPriceText(compareAtPrice) {
    this.selectedCompareAtPrice.textContent = formatMoney(compareAtPrice);
  }

  setId(id) {
    this.atcButton.dataset.atcId = id;
  }

  setAvailability(availability) {
    this.atcButton.dataset.isAvailable = availability;
  }

  getId() {
    return Number(this.atcButton.dataset.atcId);
  }

  addToCart() {
    addToCart(
      {
        items: [
          {
            id: this.getId(),
            quantity: 1,
          },
        ],
      }
    ).then((response) => {
      if (response.status == 422) {
        this.atcButton.disabled = true;
      } else {
        dispatchEvent('cart:quantity-updated', document);

        this.atcButton.disabled = true;
        this.atcButton.classList.toggle('[&:before]:hidden');
        this.atcButton.classList.toggle('[&:after]:hidden');

        dispatchEvent(DrawerEvent.Toggle, this.cartDrawer, {
          detail: {
            drawerName: 'cart',
          },
        });

        setTimeout(() => {
          this.atcButton.disabled = false;
          this.atcButton.classList.toggle('[&:before]:hidden');
          this.atcButton.classList.toggle('[&:after]:hidden');
        }, 3000);
      }
    });
  }

  registerEventListeners() {
    this.atcButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.addToCart()
    });
  }
}

customElements.define('quick-add', QuickAdd);
